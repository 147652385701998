//----------------------------------*\
//  COLORS
//----------------------------------*/


$color-black: #000;
$color-white: #fff;

$color-1: #0c0c0c;
$color-2: #e0001b;

//----------------------------------*\
//  FONTS FAMILY
//----------------------------------*/

$font-family-1-r: "MonumentExtended-Regular";
$font-family-1-b: "MonumentExtended-Ultrabold";
$font-family-2-r: "NunitoSans-Regular";
$font-family-2-b: "NunitoSans-Bold";

//----------------------------------*\
//  FONTS SIZE
//----------------------------------*/

$fs-1: 0.819rem; // (8.19px)
$fs-2: 1.024rem; // (10.24px)
$fs-3: 1.28rem; // (12.80px)
$fs-4: 1.6rem; // (16.00px)
$fs-5: 2rem; // (20.00px)
$fs-6: 2.5rem; // (25.00px)
$fs-7: 3.125rem; // (31.25px)
$fs-8: 3.906rem; // (39.06px)
$fs-9: 4.883rem; // (48.83px)
$fs-10: 6.104rem; // (61.04px)
$fs-11: 7.629rem; // (76.29px)
$fs-12: 9.537rem; // (95.37px)
$fs-13: 11.921rem; // (119.21px)
$fs-14: 14.901rem; // (149.01px)
$fs-15: 18.626rem; // (186.26px)
$fs-16: 23.283rem; // (232.83px)
$fs-17: 29.104rem; // (291.04px)
$fs-18: 36.38rem; // (363.80px)
$fs-19: 45.475rem; // (454.75px)
$fs-20: 56.843rem; // (568.43px)
$fs-21: 71.054rem; // (710.54px)
$fs-22: 88.818rem; // (888.18px)
$fs-23: 111.022rem; // (1110.22px)


// Viewport width : 1920 px
$d-xxl-1: 0.427vw; // (8.19px)
$d-xxl-2: 0.533vw; // (10.24px)
$d-xxl-3: 0.667vw; // (12.80px)
$d-xxl-4: 0.833vw; // (16.00px)
$d-xxl-5: 1.042vw; // (20.00px)
$d-xxl-6: 1.302vw; // (25.00px)
$d-xxl-7: 1.628vw; // (31.25px)
$d-xxl-8: 2.034vw; // (39.06px)
$d-xxl-9: 2.543vw; // (48.83px)
$d-xxl-10: 3.179vw; // (61.04px)
$d-xxl-11: 3.973vw; // (76.29px)
$d-xxl-12: 4.967vw; // (95.37px)
$d-xxl-13: 6.209vw; // (119.21px)
$d-xxl-14: 7.761vw; // (149.01px)
$d-xxl-15: 9.701vw; // (186.26px)
$d-xxl-16: 12.127vw; // (232.83px)
$d-xxl-17: 15.158vw; // (291.04px)
$d-xxl-18: 18.948vw; // (363.80px)
$d-xxl-19: 23.685vw; // (454.75px)
$d-xxl-20: 29.606vw; // (568.43px)
$d-xxl-21: 37.007vw; // (710.54px)
$d-xxl-22: 46.259vw; // (888.18px)
$d-xxl-23: 57.824vw; // (1110.22px)

// Viewport width : 1440 px
$d-xl-1: 0.569vw; // (8.19px)
$d-xl-2: 0.711vw; // (10.24px)
$d-xl-3: 0.889vw; // (12.80px)
$d-xl-4: 1.111vw; // (16.00px)
$d-xl-5: 1.389vw; // (20.00px)
$d-xl-6: 1.736vw; // (25.00px)
$d-xl-7: 2.170vw; // (31.25px)
$d-xl-8: 2.712vw; // (39.06px)
$d-xl-9: 3.391vw; // (48.83px)
$d-xl-10: 4.239vw; // (61.04px)
$d-xl-11: 5.298vw; // (76.29px)
$d-xl-12: 6.623vw; // (95.37px)
$d-xl-13: 8.278vw; // (119.21px)
$d-xl-14: 10.348vw; // (149.01px)
$d-xl-15: 12.935vw; // (186.26px)
$d-xl-16: 16.169vw; // (232.83px)
$d-xl-17: 20.211vw; // (291.04px)
$d-xl-18: 25.264vw; // (363.80px)
$d-xl-19: 31.580vw; // (454.75px)
$d-xl-20: 39.474vw; // (568.43px)
$d-xl-21: 49.343vw; // (710.54px)
$d-xl-22: 61.679vw; // (888.18px)
$d-xl-23: 77.099vw; // (1110.22px)

// Viewport width : 1024 px
$d-lg-1: 0.800vw; // (8.19px)
$d-lg-2: 1vw; // (10.24px)
$d-lg-3: 1.250vw; // (12.80px)
$d-lg-4: 1.563vw; // (16.00px)
$d-lg-5: 1.953vw; // (20.00px)
$d-lg-6: 2.441vw; // (25.00px)
$d-lg-7: 3.052vw; // (31.25px)
$d-lg-8: 3.814vw; // (39.06px)
$d-lg-9: 4.769vw; // (48.83px)
$d-lg-10: 5.961vw; // (61.04px)
$d-lg-11: 7.450vw; // (76.29px)
$d-lg-12: 9.313vw; // (95.37px)
$d-lg-13: 11.642vw; // (119.21px)
$d-lg-14: 14.552vw; // (149.01px)
$d-lg-15: 18.189vw; // (186.26px)
$d-lg-16: 22.737vw; // (232.83px)
$d-lg-17: 28.422vw; // (291.04px)
$d-lg-18: 35.527vw; // (363.80px)
$d-lg-19: 44.409vw; // (454.75px)
$d-lg-20: 55.511vw; // (568.43px)
$d-lg-21: 69.389vw; // (710.54px)
$d-lg-22: 86.736vw; // (888.18px)
$d-lg-23: 108.420vw; // (1110.22px)

// Viewport width : 768 px
$d-md-1: 1.066vw; // (8.19px)
$d-md-2: 1.333vw; // (10.24px)
$d-md-3: 1.667vw; // (12.80px)
$d-md-4: 2.083vw; // (16.00px)
$d-md-5: 2.604vw; // (20.00px)
$d-md-6: 3.255vw; // (25.00px)
$d-md-7: 4.069vw; // (31.25px)
$d-md-8: 5.086vw; // (39.06px)
$d-md-9: 6.358vw; // (48.83px)
$d-md-10: 7.948vw; // (61.04px)
$d-md-11: 9.934vw; // (76.29px)
$d-md-12: 12.418vw; // (95.37px)
$d-md-13: 15.522vw; // (119.21px)
$d-md-14: 19.402vw; // (149.01px)
$d-md-15: 24.253vw; // (186.26px)
$d-md-16: 30.316vw; // (232.83px)
$d-md-17: 37.896vw; // (291.04px)
$d-md-18: 47.370vw; // (363.80px)
$d-md-19: 59.212vw; // (454.75px)
$d-md-20: 74.014vw; // (568.43px)
$d-md-21: 92.518vw; // (710.54px)
$d-md-22: 115.648vw; // (888.18px)
$d-md-23: 144.560vw; // (1110.22px)

// Viewport width : 480 px
$d-sm-1: 1.706vw; // (8.19px)
$d-sm-2: 2.133vw; // (10.24px)
$d-sm-3: 2.667vw; // (12.80px)
$d-sm-4: 3.333vw; // (16.00px)
$d-sm-5: 4.167vw; // (20.00px)
$d-sm-6: 5.208vw; // (25.00px)
$d-sm-7: 6.510vw; // (31.25px)
$d-sm-8: 8.138vw; // (39.06px)
$d-sm-9: 10.173vw; // (48.83px)
$d-sm-10: 12.717vw; // (61.04px)
$d-sm-11: 15.894vw; // (76.29px)
$d-sm-12: 19.869vw; // (95.37px)
$d-sm-13: 24.835vw; // (119.21px)
$d-sm-14: 31.044vw; // (149.01px)
$d-sm-15: 38.804vw; // (186.26px)
$d-sm-16: 48.506vw; // (232.83px)
$d-sm-17: 60.633vw; // (291.04px)
$d-sm-18: 75.792vw; // (363.80px)
$d-sm-19: 94.740vw; // (454.75px)
$d-sm-20: 118.423vw; // (568.43px)
$d-sm-21: 148.029vw; // (710.54px)
$d-sm-22: 185.037vw; // (888.18px)
$d-sm-23: 231.296vw; // (1110.22px)
