@keyframes titleAnimation {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}


@keyframes rotateAnim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes loaderAnim {
  0% {
    fill: white;
  }

  25% {
    fill: rgb(187, 162, 162);
  }

  50% {
    fill: rgb(151, 105, 105);
  }

  75% {
    fill: rgb(124, 70, 70);
  }

  100% {
    fill: rgb(92, 34, 34);
  }
}


.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 0;
}
