.ahdc-menu {
  width: 100%;


  &__container {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 85vw;
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    mix-blend-mode: difference;
    @include respond(t-m) {
      padding: 3rem;
      width: 100%;
      left: 0;
      transform: unset;
    }

    @include respond(p-xl) {
      padding: 2rem;
    }
  }

  &__logo-link {
    &:link,
    &:visited {
      display: block;
      z-index: 999;
      mix-blend-mode: difference;
    }
  }

  &__logo {
    width: 100px;

    @include respond(t-m) {
      width: 80px;
    }
  }

  &__toggle-btn {
    display: block;
    mix-blend-mode: difference;
    z-index: 999;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }

  &__toggle-line {
    display: block;
    width: 3rem;
    height: 0.4rem;
    background-color: $color-white;
    transform: translate3d(0,0,0) rotate(0deg);
    will-change: transform;

    &--color {
      background-color: transparent;
    }
  }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9;
  }

  &__overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0,0,0);
    will-change: transform;
    
    &--white {
      background-color: $color-white;
    }

    &--black {
      background-color: $color-1;
    }
  }

  &__bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: multiply;
    transform: translate3d(0,0,0) scale(1);
    will-change: transform, opacity;
  }

  &__nav {
    position: relative;
    margin-top: auto;
    margin-bottom: -5%;
    z-index: 9999;
    
    @include respond(t-m) {
      margin: unset;
    }
  }

  &__list {
    list-style: none;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-size: $d-xxl-12;
      text-decoration: none;
      text-transform: capitalize;
      color: $color-white;
      line-height: 1;
      transition: color .3s ease-in-out;
      transform: translate3d(0,0,0);
      // -webkit-text-stroke: 2px transparent;
      // -webkit-text-fill-color: none;
      // transition: -webkit-text-stroke -webkit-text-fill-color 1s ease-out;
      @include respond(t-m) {
        font-size: $d-xxl-13;
      }

      @include respond(p-xl) {
        font-size: $d-xxl-15;
      }
    }

    &:hover {
      // -webkit-text-stroke: 2px $color-white;
      // -webkit-text-fill-color: transparent;
    }

    &--active {
      &:link,
      &:visited {
        position: relative;
        -webkit-text-stroke: 2px $color-white;
        -webkit-text-fill-color: transparent;
        transition: -webkit-text-stroke .3s ease-in-out;

        @include respond(t-m) {
          -webkit-text-stroke: 1px $color-white;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translate(-50%, -50%);
        background-color: $color-white;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        transition: background-color .3s ease-in-out;
      }
    }
  }



  &__footer {
    margin-top: auto;
    z-index: 999;
    width: 85vw;
    padding: 3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(t-m) {
      flex-direction: column;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      margin-top: unset;
    }
  }

  &__sm-list {
    list-style: none;
    display: flex;
  }

  &__sm-item {
    position: relative;
    overflow: hidden;
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  &__sm-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-1;
    transform: translate3d(0,0,0);
  }

  &__sm-link {
    &:link,
    &:visited {
      display: block;
      text-transform: capitalize;
      text-decoration: none;
      color: $color-white;
      transition: color .3s ease-in-out;
    }
  }

  &__copyright {
    @include respond(t-m) {
      margin-top: 1rem;
    }
  }

  &__item--inactive > &__link,
  &__sm-item--inactive > &__sm-link  {
    &:link,
    &:visited {
      color: rgba($color-white,.3);
      transition: opacity .5s ease-in-out;
    }
  }

  &__item--inactive > &__link--active {
    &:link,
    &:visited {
      -webkit-text-stroke: 2px rgba($color-white,.3);
      -webkit-text-fill-color: transparent;
    }

    &::before {
      opacity: .3;
    }
  }


}
