.ahdc-link {
    &:link,
    &:visited {
        position: relative;
        display: block;
        font-size: 1rem;
        color: $color-white;
        text-decoration: none;
        border: 3px solid $color-white;
        padding:1rem 1.7rem;
        text-transform: capitalize;
        overflow: hidden;
        mix-blend-mode: difference;
        transform: translate3d(0,0,0); 
    }

    &--border-transparent {
        &:link, 
        &:visited {
            border: none;
            margin-left: 2.6rem;
            @include respond(t-m) {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
    }

    &__mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        z-index: -1;
        pointer-events: none;
        transform: translate3d(-100%,0,0); 
    }

    &__border {
        position: absolute;
        bottom: 0;
        left: -100%;
        width: 100%;
        height: 3px;
        background-color: $color-white;
        z-index: -1;
        pointer-events: none;
    }
}
