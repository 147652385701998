.ahdc-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    width: 100%;
    padding: 3rem 2.5rem;
    color: $color-1;
    font-size: $fs-1;
    margin-top: 15rem;

    @include respond(t-xl) {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);
        grid-row-gap: 1rem;
        padding: 1.5rem;
    }

    @include respond(t-m) {
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: repeat(3,1fr);
        margin-top: 5rem;
    }
    
    @include respond(p-xl) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &__sm-list {
        list-style: none;
        display: flex;
        @include respond(t-xl) {
            grid-column: 1/2;
            grid-row: 1/2;
            justify-self: start;
        }

        @include respond(t-m) {
            grid-column: unset;
            grid-row: unset;
            justify-self: center;
        }

        @include respond(p-xl) {
            flex-direction: column;
        }
    }

    &__sm-item {
        &:not(:last-child) {
            margin-right: 2rem;
            @include respond(p-xl) {
                margin-right: 0;
            }
        }

        @include respond(p-xl) {
            margin-top: .5rem;
        }
    }

    &__sm-link {
        &:link,
        &:visited {
            text-decoration: none;
            color: $color-1;
            transition: color .3s ease-in-out;
        }
    }

    &__copyright {
        
        @include respond(t-xl) {
            margin-left: 0;
            grid-column: 1/3;
            grid-row: 2/3;
            justify-self: center;
        }

        @include respond(t-m) {
            grid-column: unset;
            grid-row: unset;
            justify-self: center;
        }


        @include respond(p-xl) {
            order: 3;
        }
    }

    &__backtotop {
        display: block;
        @include respond(t-xl) {
            grid-column: 2/3;
            grid-row: 1/2;
            justify-self: end;
        }

        @include respond(t-m) {
            grid-column: unset;
            grid-row: unset;
            justify-self: center;
        }
    }

    &__link {
        &:link,
        &:visited {
            display: inline-block;
            color: $color-1;
            text-decoration: none;
            cursor: pointer;
            transition: color .3s ease-in-out;
        }

        &:hover {
            color: rgba($color-1,.3);
        }
    }

    &__sm-item--inactive > &__sm-link  {
      &:link,
      &:visited {
        color: rgba($color-1,.3);
        transition: opacity .5s ease-in-out;
      }
    }
}