.ahdc-contact-me {

    &:link,
    &:visited {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        color: $color-white;
        text-decoration: none;
        margin-top: 15rem ;
        transition: color .3s ease-in-out;
        
        @include respond(t-m) {
            margin-top: 5rem;
        }

        &:hover {
            color:rgba($color-white, .3);
        }
    }

    &__subtitle {
        font-family: $font-family-2-b;
        font-size: $d-xxl-8;
        letter-spacing: 5px;
        margin-bottom: 2.5rem;
        pointer-events: none;
        text-align: center;

        @include respond(t-m) {
            font-size: $d-xxl-10;
        }

        @include respond(p-xl) {
            font-size: $d-xxl-12;
        }
    }

    &__title {
        font-size: $d-xxl-12;
        text-transform: capitalize;
        pointer-events: none;
        text-align: center;

        @include respond(t-m) {
            font-size: $d-xxl-14;
        }

        @include respond(p-xl) {
            font-size: $d-xxl-16;
        }
    }

    &__icon {
        color: $color-white;
        font-size: $d-xxl-14;
        transition: color .3s ease-in-out;
        pointer-events: none;

        @include respond(t-m) {
            font-size: $d-xxl-16;
        }

        @include respond(p-xl) {
            font-size: $d-xxl-18;
        }
    }

    &:link:hover &__icon,
    &:visited:hover &__icon { 
        color: rgba($color-white, .3);
    }
}