.ahdc-work {
    width: 100%;
    padding: 6rem 0 0 0;
    @include respond(t-xl) {
        padding: 1rem 0 0 0;
    }

    &__content {

    }

    &__description {
        display: flex;
        margin-bottom: 6rem;
        @include respond(t-xl) {
            flex-direction: column;
            margin-bottom: 1rem;
        }
    }

    &__column {
        padding: 3rem;
        overflow: hidden;
        @include respond(t-xl) {
            padding: 2rem;
        }

        &--left {
            flex : 0 0 75%;
            @include respond(t-xl) {
                flex : 0 0 100%;
                order: 1;
            }
        }

        &--right {
            flex: 0 0 25%;
            @include respond(t-xl) {
                flex : 0 0 100%;
                order: 0;
            }
        }
    }

    &__column--left {

        h3 , p {
            overflow: hidden;
            transform: translate3d(0,0,0);
        }

        p {
            font-family: $font-family-2-r;
            margin-bottom: 1.5rem;
        }
    }

    &__column--right {
        border-top: 1px solid rgba($color-white, .05);
        border-bottom: 1px solid rgba($color-white, .05);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__list {
        list-style: none;
        text-align: center;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

    &__heading {
        letter-spacing: 1px;
        text-transform: capitalize;
        overflow: hidden;
        transform: translate3d(0,0,0);
    }   

    &__text {
        font-family: $font-family-2-r;
        overflow: hidden;
        transform: translate3d(0,0,0);
    }

    &__images {

    }

    &__image-wrapper {
        transform: translate3d(0,0,0);
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

    &__image {
        width: 100%;
    }

    &__link-wrapper {
        margin-top: 10rem ;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate3d(0,0,0);
    }

}