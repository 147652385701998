.ahdc-hero {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;    

    &__column {
        &--left {
            transform: translate3d(0,0,0);
            will-change: transform, opacity;
           z-index: 1;
           @include respond(t-m) {
            margin-top: auto;
            margin-bottom: 15%;
            }

            @include respond(t-s) {
                margin-bottom: 35%;
            }
        }

        &--right {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0,-50%);
            width: 55%;

            @include respond(t-m) {
                right: unset;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 100%;
                top: 40%;
            }
        }
    }

    &__about {
        @include respond(t-m) {
            width: 100%;
        }
    }

    &__line {
        overflow: hidden;
        &:not(:last-child) {
            margin-bottom: 1rem;
            @include respond(p-xl) {
                margin-bottom: 0;
            }
        }
    }



    &__text {
        padding: 0 0 .3rem 0;
        display: inline-block;
        font-size: $d-xxl-10;
        text-transform: capitalize;
        line-height: 1;
        transform: translate3d(0,0,0);
        will-change: transform, opacity;

        @include respond(t-m) {
            font-size: $d-xxl-12;
        }

        @include respond(p-xl) {
            font-size: $d-xxl-14;
        }
    }

    &__links {
        display: flex;
        align-items: center;
        margin-top: 3.5rem;

        a:not(:nth-child(1)) {
            margin-left: 2.6rem;
            @include respond(t-m) {
                margin-left: 0;
                margin-top: 1rem;
            }
        }
        @include respond(t-m) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 2.5rem;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__mark-box {
        position: absolute;
        top: calc(100% - 150px);
        left: calc(85vw - 100%);
        width: 190px; 

        @include respond(t-m) {
            width: 150px; 
            left: unset;
            right: calc(85vw - 100%);
        }
    }

    &__circle {
        animation: rotateAnim 8.5s linear infinite forwards;
        transform-origin: center center;
        backface-visibility: hidden;
    }

    &__arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 7rem;
        fill: transparent;
        stroke:$color-white;
        stroke-width: .5;
        @include respond(t-m) {
            font-size: 5rem;
        }
    }

}