.ahdc-workblock {
    width: 100%;
    margin-top: 10rem;
    
    @include respond(t-m) {
        margin-top: 1rem;
    }
    
    &__heading {
        position: relative;
        width: fit-content;
        height: auto;
        font-size: $d-xl-14;
        text-transform: uppercase;
        margin-bottom: 5rem;
        transform: translateX(0%);
        display: flex;
        animation: titleAnimation 35s linear infinite;

        @include respond(t-m) {
            font-size: $d-xl-15;
            margin:5rem 0;
            animation: titleAnimation 20s linear infinite;
        }
    }

    &__line {
        &:nth-child(even) {
            -webkit-text-stroke: 2px $color-white;
            -webkit-text-fill-color: transparent;
            @include respond(t-m) {
                -webkit-text-stroke: 1px $color-white;
            }
        }
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(1,40rem);
        grid-gap:5rem;

        @include respond(t-xl) {
            grid-template-rows: repeat(1,35rem);
        }

        @include respond(t-m) {
            grid-template-rows: repeat(2,35rem);
            grid-template-columns: repeat(1,1fr);
        }
    }

    &__work {
        position: relative;
        padding: 3rem;
        overflow: hidden;
        transform: translate3d(0,0,0) scale(1) skew(0,0);
    }

    &__mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    &__link {
        &:link,
        &:visited {
            display: block;
            text-decoration: none;
        }
    }

    &__title {
        position: relative;
        font-size: $d-xxl-11;
        color: $color-white;
        text-transform: uppercase;
        z-index: 2;
        pointer-events: none;
        
        @include respond(t-m) {
            font-size: $d-xxl-14;
        }

        @include respond(p-xl) {
            font-size: $d-xxl-15;
        }
    }

    &__img {
        position: absolute;
        opacity: 0.8;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $fs-3;
        color: $color-white;
        z-index: 3;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 3rem;
        opacity: 0;
        transform: translate3d(-15px,0,0);
        pointer-events: none;

        @include respond(t-m) {
            opacity: 1;
            transform: translate3d(0,0,0);
        }
    }

    &__icon {
        font-size: 2rem;
        margin-left: .5rem;
    }

    &__link-box {
        margin-top: 10rem ;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate3d(0,0,0);
    }
}