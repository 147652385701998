.ahdc-intro {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    z-index: 999999;
    overflow: hidden;

    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 150px;
        z-index: 9999999;
        mix-blend-mode: difference;
    }

    &__column {
        position: relative;
        width: 50%;
        height: 100%;

        &--left {

        }

        &--right {

        }
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        &--black {
            background-color: $color-1;
        }

        &--black-left {
            top: 0;
        }

        &--black-right {
            bottom: 0;
        }

        &--white {
            background-color: $color-white;
        }

        &--white-left {
            top: -100%;
        }

        &--white-right {
            bottom: -100%;
        }
    }
}