.ahdc-page-not-found {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--animate {
        transform: translate3d(0,0,0);
    }

    &__text {
        text-transform: uppercase;
        line-height: 1;
        &--1 {
            font-size: $d-xl-16;
            -webkit-text-stroke: 4px $color-white;
            -webkit-text-fill-color: transparent;
            @include respond(t-m) {
                font-size: $d-xl-17;
            }

            @include respond(p-m) {
                font-size: $d-xl-18;
            }
        }

        &--2 {
            text-transform: uppercase;
            font-size: $d-xl-9;
            text-align: center;
            margin-bottom: 3.5rem;
            @include respond(t-m) {
                font-size: $d-xl-10;
            }

            @include respond(p-m) {
                font-size: $d-xl-12;
            }
        }

    }

    &__img-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: .5;
        
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: multiply;
    }
}