.ahdc-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    width: 100%;
    height: 100vh;
    
    &__loader {
        display: block;
        font-size: $d-xl-14;
        color: $color-white;
    }
}