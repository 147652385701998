* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 300;
    //cursor: none;
}

html ,
body {
    font-family: $font-family-1-r, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size:100%;
    width: 100%;
    height: 100%;
    background-color: $color-1;
    color:$color-white;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    scroll-behavior: smooth;
    //cursor: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
}


body {
    overflow-x: hidden;
    overflow-y: scroll;
}

#root {
    width: 100%;
}




