.ahdc-main {
    position: relative;
    width: 100%;
    height: 100%;

    // Load 
    &__load-container {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 999999999;
        pointer-events: none;
        opacity: 0;
    }

    &__load-screen {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        transform: translate3d(0,0,0);
        pointer-events: none;
    }

    &__load-logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%,-50%,0);    
        width: 150px;
        pointer-events: none;
        @include respond(t-m) {
            width: 100px;
        }
    }

    &__loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        z-index: 999999999;
    }

    &__progress {
        position: fixed;
        top: 0;
        right: 0;
        width: 4px;
        height: 100%;
        background-color: $color-white;
        transform: translate3d(0,0,0);
        
        @include respond(t-m) {
            left: 0;
            right: unset;
        }
    }
    
    &__lines {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 85vw;
        height: 100%;
        transform: translate(-50%, -50%);
        z-index: 0;

        // @include respond(d-l) {
        //     width: 90vw;
        // }
    }

    &__line {
        position: absolute;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: rgba($color-white, .05);

        &--1 {
            left: 0;
        }

        &--2 {
            left: 25%;
        }

        &--3 {
            left: 50%;
        }

        &--4 {
            left: 75%;
        }

        &--5 {
            left: 100%;
        }
    }
    
    &__wrapper {
        position: relative;
        z-index: 1;
        width: 85vw;
        margin: 0 auto;
    }

    &__container {
        opacity: 0;
    }

    &__content {
        
    }
}