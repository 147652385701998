.ahdc-pagehero {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .1;
  }

  &__title {
    display: block;
    font-size: $fs-9;
    text-transform: uppercase;
    letter-spacing: 15px;
    line-height: 1;
    overflow: hidden;
    text-align: center;

    @include respond(t-m) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include respond(p-xl) {
      font-size: $fs-7;
    }
  }

  &__char {
    display: inline-block;
    transform: translate3d(0,0,0) skew(0,0);
    will-change: transform, opacity;

    @include respond(t-m) {
      &:not(:last-child) {
        margin-bottom: .5rem;
      }
    }
  }
}
