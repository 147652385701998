// @font-face {
//     font-family: 'MyWebFont';
//     src: url('webfont.eot'); /* IE9 Compat Modes */
//     src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//          url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
//          url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
//          url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
//          url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
//   }


// @include font-face("Axis", font-files("axis.otf"));
// @include font-face("Axis-Bold", font-files("axis.bold.otf"));
// @include font-face("Montserrat-Regular", font-files("Montserrat-Regular.ttf"));
// @include font-face("Pacifico-Regular", font-files("Pacifico-Regular.ttf"));
// @include font-face("Streetwear", font-files("Streetwear.otf"));


//MonumentExtended

@font-face {
    font-family: 'MonumentExtended-Regular';
    src:url('../../fonts/MonumentExtended-Regular.otf');
}

@font-face {
    font-family: 'MonumentExtended-Ultrabold';
    src:url('../../fonts/MonumentExtended-Ultrabold.otf');
}

//NunitoSans

@font-face {
    font-family: 'NunitoSans-Regular';
    src:url('../../fonts/NunitoSans-Regular.ttf');
}

@font-face {
    font-family: 'NunitoSans-Bold';
    src:url('../../fonts/NunitoSans-Bold.ttf');
}














