.ahdc-about {
    width: 100%;
    padding: 6rem 0 0 0;

    @include respond(t-m) {
        padding: 0;
    }

    &__content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        @include respond(t-xl) {
            flex-direction: column;
        }
    }

    &__column {
        position: relative;
        padding: 0 3rem;
        flex: 0 0 50%;
        overflow: hidden;

        @include respond(p-xl) {
            padding: 0;
        }

        &--right {
            @include respond(t-xl) {
                margin-top: 2rem;
            }
        }
    }


    &__image-wrapper {
        transform: translate3d(0,0,0);
    }

    &__image {
        width: 100%;
    }

    &__description {
        font-size: $fs-3;
        transform: translate3d(0,0,0);
        
        h3 {
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 1rem;
        }

        p {
            font-family: $font-family-2-r ;
            margin-bottom: 1rem;
            // text-align: justify;
        }
    }
}