.ahdc-contact {
  padding: 10rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include respond(t-xl) {
    padding: 2rem 0 6rem 0;
    flex-direction: column;
  }

  &__column {
    &--left {
      flex: 0 0 50%;
      @include respond(t-xl) {
        flex: none;
      }
    }

    &--right {
      flex: 0 0 50%;
      @include respond(t-xl) {
        flex: none;
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  &__title {
    font-size: $fs-4;
    text-transform: capitalize;
    margin-bottom: 1rem;

    @include respond(t-xl) {
      font-size: $fs-4;
    }

    &--sm {
      font-size: $fs-4;
      margin-top: 3rem;
      @include respond(t-xl) {
        font-size: $fs-4;
      }
    }
  }

  &__info {
    font-family: $font-family-2-r;
    font-size: $fs-3;
    
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__para {
    font-family: $font-family-2-r;
    font-size: $fs-4;
    @include respond(t-xl) {
      font-size: $fs-3;
    }
  }

  &__form {
    width: 100%;
  }

  &__fields-group {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    @include respond(t-xl) {
      display: block;
    }
  }

  &__field-group {
    position: relative;
    height: 55px;
    margin-bottom: 2rem;

    &--mr {
      margin-right: 1rem;
      @include respond(t-xl) {
        margin-right: 0;
      }
    }

    &--ml {
      margin-left: 1rem;
      @include respond(t-xl) {
        margin-left: 0;
      }
    }

    &--textarea {
      height: 110px;
      margin-bottom: 0;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $color-white;
      transition: width 0.3s ease-in;
    }

    &:focus::after {
      width: 100%;
    }
  }

  &__label {
    position: absolute;
    top: 2rem;
    left: 0;
    font-size: $fs-1;
    font-family: $font-family-2-b;
    opacity: 1;
    transition: top 0.2s ease-in-out;
  }

  &__error-message {
    display: block;
    font-size: $fs-1;
    font-family: $font-family-2-b;
    color: $color-2;
  }

  &__field {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-bottom: 1px solid rgba($color-white, 0.5);
    border-top: none;
    border-left: none;
    border-right: none;
    color: $color-white;
    padding-top: 2rem;

    &:focus {
      outline: none;
    }
  }

  &__field:focus + &__label,
  &__field:not(:placeholder-shown) + &__label {
    top: 0;
    opacity: 0.5;
  }

  // &__field:focus {
  //     border-bottom: 1px solid rgba($color-white, 1);
  // }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $color-1 inset;
    -webkit-text-fill-color: #fff !important;
  }

  textarea[name="message"] {
    resize: none;
  }

  &__submit {
    display: block;
    font-size: 1rem;
    color: $color-white;
    border: 3px solid $color-white;
    padding: 1rem 1.7rem;
    text-transform: capitalize;
    overflow: hidden;
    background-color: transparent;
    font-family: $font-family-1-r;
    margin-top: 3.5rem;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &--disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}
