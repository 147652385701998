.cursor {
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $color-white;
    z-index: 999999;
    mix-blend-mode: difference;
    pointer-events: none;
    // transition: all 200ms linear;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba($color-white, 0.1);
    }
}
